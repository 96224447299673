.bg-green-500 {
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
}

.bg-red-700 {
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
}

@keyframes bounce-left {
  0%,
  100% {
    transform: translateX(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
.animate-bounce-left {
  animation: bounce-left 1s infinite;
}

@keyframes bounce-right {
  0%,
  100% {
    transform: translateX(25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
.animate-bounce-right {
  animation: bounce-right 1s infinite;
}

@keyframes bounce-up {
  0%,
  100% {
    transform: translateY(25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
.animate-bounce-up {
  animation: bounce-up 1s infinite;
}
