@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

.hero {
  background: linear-gradient(90deg, #280064, #2100ec 50%, #280064);
}

.button-start {
  background: #f00975;
}

.hero-line-img {
  transform: translateX(-50%);
}

.overvideo-bg {
  background: rgba(0, 0, 0, 0.3);
}

.max-w-screen-1120 {
  max-width: 1120px;
}

.description-items {
  display: grid;
  grid-template-rows: 300px 1fr;
  grid-template-columns: repeat(2, minmax(auto, 592px));
  grid-column-gap: 8px;
  align-items: start;
}

.description-item {
  min-width: 144px;
}

.catalog {
  background: #ebebeb;
}

.catalog-blue {
  background: linear-gradient(90deg, #250198, #280064);
}
/* 
.simulations-item {
  max-width: 352px;
} */

.simulations-item-img {
  max-width: 352px;
  max-height: 304px;
}

.simulations-item-img .img {
  width: 352px;
  height: 304px;
}

.simulations-item-img .filter {
  background: linear-gradient(
    0deg,
    rgba(27, 3, 75, 0.6) 7.29%,
    hsla(0, 0%, 61.6%, 0) 53.65%
  );
}

.slider-slide {
  width: 864px;
}

.slider-youtube {
  padding-bottom: 56.25%;
}

.slider-avatar {
  width: 320px;
  height: 320px;
}

.slider-avatar-text {
  width: 504px;
}

.swiper-button-prev {
  left: 0px;
}

.swiper-button-next {
  right: 0px;
}

.w-fit {
  width: fit-content;
}

.simulation-video {
  aspect-ratio: 16/9;
}

.no-underline {
  text-decoration: none !important;
}

/* @media (max-width: 599px) {
  .hero h1 {
    padding: 48px 0 65px;
    line-height: 50px;
  }
  .description-h2 {
    margin: 32px 65px 40px;
    line-height: 27px;
  }
  .description-items {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: 0;
  }
  .simulations-item-img {
    max-height: 216px;
  }
  .simulations-item-img .img {
    width: 344px;
    height: 216px;
  }
  .slider-avatar {
    width: 296px;
    height: 296px;
  }
  .slider-avatar-text {
    width: 344px;
  }
  .default-nav {
    display: none !important;
  }
} */
