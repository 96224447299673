.paragraph-viewer .ql-toolbar {
  display: none;
}

.ql-container.ql-snow {
  border: none !important;
}
.ql-container.ql-snow {
  border: none !important;
}

.ql-editor {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-bottom: 0.5rem !important;
  font-family: Montserrat;

  padding: 0 !important;
}

.ql-editor ul,
.ql-editor li {
  padding-left: 0px !important;
}

.ql-editor ol {
  padding-left: 0.8rem !important;
}
