label {
  top: 0%;
  transform: translateY(-50%);
  font-size: 11px;
  color: rgba(70, 70, 70, 1);

  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 45%,
    rgba(255, 255, 255, 1) 45%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

.selectbutton {
  min-height: 2.5rem;
}

.selectbutton button:focus {
  outline: none;
  border-color: rgba(37, 99, 235, 1);
}
