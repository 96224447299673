.simulation-page-head {
  max-height: 256px;
}

.simulation-title {
  max-width: 1120px;
  transform: translateX(-50%);
}

.simulation-gradient {
  background: linear-gradient(
    10deg,
    rgba(27, 3, 75, 0.4) 7.29%,
    hsla(0, 0%, 61.6%, 0) 53.65%
  );
}

.simulation-image {
  max-height: 256px;
  object-fit: cover;
  filter: blur(8px);
}

.simulation-about-minutes {
  color: #457dff;
  text-transform: none;
}

.simulation-description {
  box-shadow: 4px 8px 10px rgba(0, 0, 0, 0.2);
}

.simulation-screenshots,
.simulation-collaborations {
  max-width: 1120px;
}

.simulation-authors {
  max-width: 544px;
}

.simulation-video {
  aspect-ratio: 16/9;
}
